const CryptoJSEncBase64 = require("crypto-js/enc-base64");
const CryptoJSHMACSHA1 = require("crypto-js/hmac-sha1");
const {
  DEFAULT_PHASE,
  DEFAULT_PHASE_ONE,
  DEFAULT_TARIFF,
  DEFAULT_TARIFF_CIRCUIT_BREAKERS_ID,
  DEFAULT_TARIFF_CIRCUIT_BREAKERS_SME_ID,
  GAS,
} = require("../constants");

const config = require("../configs");
const { get: cacheGet, set: cacheSet, has: cacheHas } = require("../helpers/cache");

const promiseTimeout = require("../helpers/promiseTimeout");

require("es6-promise").polyfill();
require("isomorphic-fetch");

/* Natahne aktivni list dodavateli */

const secretKey = "b3dcdd69f501b23dbbc0a3627tf4cc75";
const publicKey = "e4bbda3c9f54acb124d2c8415af10bc9";

const constructUrlForApiCall = (urlBase, callData) => {
  let sig = CryptoJSEncBase64.stringify(CryptoJSHMACSHA1(JSON.stringify(callData), secretKey)); // eslint-disable-line new-cap
  let dataForUrl = {
    data: JSON.stringify(callData), // eslint-disable-line id-blacklist
    sig: sig,
    pubKey: publicKey,
  };
  return urlBase + encodeURIComponent(JSON.stringify(dataForUrl));
};

const getApiUrl = (commodity, type) => {
  const commodityUrlPart = commodity === "gas" ? "/gas" : "/electricity";
  const apiUrls = {
    zipCodeUrl: `${config.serverUrl}/psc/`,
    getSuppliersList: `${config.apiUrl}/api/list/suppliers?data=`,
    consumptionByAdvance: `${config.apiUrl}/api${commodityUrlPart}/consumption-by-advance?data=`,
    consumptionByUse: `${config.apiUrl}/api${commodityUrlPart}/consumption-by-use?data=`,
    rates: `${config.apiUrl}/api/electricity/rates?data=`,
    breakers: `${config.apiUrl}/api/electricity/circuit-breakers?data=`,
    reservation: `${config.apiUrl}/api/gas/get-phone-reservation-day/`,
    lead: `${config.serverUrl}/p${commodityUrlPart}/api/send-phone-reservation`,
    leadApi: `${config.serverUrl}/p/api/v1/lead`,
    gasTariffs: `${config.apiUrl}/api/gas/tariffs?data=`,
    electricityTariffs: `${config.apiUrl}/api/electricity/tariffs?data=`,
    feedback: `${config.serverUrl}/api/save-feedback`,
    poll: `${config.serverUrl}/api/poll-ids`,
    offerCodes: `${config.serverUrl}/api/offer-codes`,
    retailOfferCodes: `${config.serverUrl}/api/retail-offer-codes`,
    sendOffer: `${config.serverUrl}/p${commodityUrlPart}/api/send-offer`,
    maintenance: `${config.adminUrl}/api/maintenance`,
    leadMaintenance: `${config.adminUrl}/api/lead`,
    "info-bar": `${config.adminUrl}/api/info-bar`,
  };

  return apiUrls[type];
};
module.exports.getApiUrl = getApiUrl;

module.exports.getSuppliers = async (commodity, isCompany) => {
  const requestUrl = getApiUrl(commodity, "getSuppliersList");

  const response = await fetch(
    constructUrlForApiCall(requestUrl, {
      commodity: commodity === "electricity" ? "ele" : "gas",
      isCompany: Boolean(isCompany),
      onlyActive: true,
      priceListSelectionMethod: "supplierDefaultWebPriceList",
    })
  );

  try {
    const suppliersRaw = await response.json();

    return {
      top: suppliersRaw.data.topSuppliers.map(item => ({
        value: item.commercialPriceList.id,
        label: item.supplierName,
      })),
      all: suppliersRaw.data.otherSuppliers.map(item => ({
        value: item.commercialPriceList.id,
        label: item.supplierName,
      })),
    };
  } catch (err) {
    return {
      top: [],
      all: [],
    };
  }
};

module.exports.getDefaultSupplierId = (suppliers, commodity) => {
  if (!suppliers.top || suppliers.top && suppliers.top.length === 0) {
    return null;
  }
  let currentSupplierID = suppliers.top[0].value;
  let currentSupplierEleID = suppliers.top[0].value;
  try {
    currentSupplierID = suppliers.top.find(item => item.label.substr(0, 6) === "innogy").value;
  } catch (e) {}

  try {
    currentSupplierEleID = suppliers.top.find(item => item.label.substr(0, 3) === "ČEZ").value;
  } catch (e) {}

  let currentSupplier = commodity === "gas" ? currentSupplierID : currentSupplierEleID;
  return currentSupplier.toString();
};

module.exports.getConsumptionByAdvance = async (commodity, consumptionByAdvanceData) => {
  const cacheKey = JSON.stringify({ commodity, consumptionByAdvanceData });

  if (cacheHas(cacheKey)) {
    return cacheGet(cacheKey);
  }

  const requestUrl = getApiUrl(commodity, "consumptionByAdvance");
  try {
    const response = await fetch(constructUrlForApiCall(requestUrl, consumptionByAdvanceData));
    const consumptionRaw = await response.json();

    if (consumptionRaw.status !== 1) {
      throw consumptionRaw;
    }
    cacheSet(cacheKey, consumptionRaw.data);

    return consumptionRaw.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

module.exports.getConsumptionByUse = async (commodity, consumptionByUseData) => {
  const requestUrl = getApiUrl(commodity, "consumptionByUse");

  try {
    const response = await fetch(constructUrlForApiCall(requestUrl, consumptionByUseData));
    const consumptionRaw = await response.json();
    if (consumptionRaw.status !== 1) {
      throw consumptionRaw;
    }

    return consumptionRaw.data.consumption;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const tranformRatesOptions = ratesObject => {
  return Object.keys(ratesObject).map(key => {
    const rate = ratesObject[key];
    return {
      value: rate.id,
      label: rate.name,
      allows_both_tariffs: rate.allows_both_tariffs,
    };
  });
};

module.exports.getRates = async () => {
  const requestUrl = getApiUrl("electricity", "rates");

  try {
    const response = await fetch(constructUrlForApiCall(requestUrl, {}));
    const tariffsData = await response.json();
    if (tariffsData.status !== 1) {
      throw tariffsData;
    }

    let rates = {
      household: tranformRatesOptions(tariffsData.data.household),
      sme: tranformRatesOptions(tariffsData.data.company),
    };

    return rates;
  } catch (e) {
    console.log(e);
    return null;
  }
};

module.exports.getBreakers = async () => {
  const requestUrl = getApiUrl("electricity", "breakers");

  let params = [
    {
      phase: DEFAULT_PHASE_ONE,
      tariff: DEFAULT_TARIFF_CIRCUIT_BREAKERS_ID,
      key: "household",
    },
    {
      phase: DEFAULT_PHASE,
      tariff: DEFAULT_TARIFF_CIRCUIT_BREAKERS_ID,
      key: "household",
    },
    {
      phase: DEFAULT_PHASE_ONE,
      tariff: DEFAULT_TARIFF_CIRCUIT_BREAKERS_SME_ID,
      key: "sme",
    },
    {
      phase: DEFAULT_PHASE,
      tariff: DEFAULT_TARIFF_CIRCUIT_BREAKERS_SME_ID,
      key: "sme",
    },
  ];

  const breakers = await Promise.all(
    params.map(async paramSet => {
      const response = await fetch(
        constructUrlForApiCall(requestUrl, {
          phase: paramSet.phase,
          tariff: paramSet.tariff,
        })
      );
      return response.json();
    })
  );

  return breakers.reduce(
    (acc, { data }, index) => {
      const source = params[index];

      data.forEach(dataItem => {
        acc[source.key].push({
          value: `${source.phase}_${dataItem.id}`,
          label: `${source.phase} fáze – ${dataItem.name}`,
          variableAmperes: Boolean(dataItem.name.match(/za každý/)),
        });
      });

      return acc;
    },
    { household: [], sme: [] }
  );
};

module.exports.getCallbackTimesForDay = async date => {
  const response = await fetch(
    getApiUrl("gas", "reservation") +
      `?date=${`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}&way=&table=1&chosen_date=&chosen_time=&other_time=`
  );
  const rawData = await response.json();
  const times = [];
  let matches = [];

  const regex = /<label[^>]+><input.*?val="(\d+)">([^<]+)<\/label>/gm;
  while ((matches = regex.exec(rawData.day_table))) {
    times.push({
      label: matches[2],
      value: matches[1],
      disabled: Boolean(matches[0].match(/disable/)),
    });
  }
  return times;
};

module.exports.getMaintenance = async () => {
  try {
    const response = await fetch(getApiUrl("", "maintenance"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });

    const rawData = await response.json();

    const mdata = {
      maintenance_from: {
        date: rawData.data.attributes.maintenanceFrom,
        timezone: "Europe/Prague",
        timezone_type: 3,
      },
      maintenance_to: {
        date: rawData.data.attributes.maintenanceTo,
        timezone: "Europe/Prague",
        timezone_type: 3,
      },
      maintenance_in_progress: new Date(rawData.data.attributes.maintenanceFrom) < new Date() && new Date() < new Date(rawData.data.attributes.maintenanceTo),
      maintenance_end_time_text: rawData.data.attributes.description,
    };

    // console.log(mdata);

    return mdata;
  } catch (e) {
    console.log(e);
  }
  return false;
};

module.exports.getLeadMaintenance = async () => {
  try {
    const response = await fetch(getApiUrl("", "leadMaintenance"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });

    const rawData = await response.json();

    const mdata = {
      lead_maintenance_from: {
        date: rawData.data.attributes.from,
        timezone: "Europe/Prague",
        timezone_type: 3,
      },
      lead_maintenance_to: {
        date: rawData.data.attributes.to,
        timezone: "Europe/Prague",
        timezone_type: 3,
      },
      lead_maintenance_in_progress: new Date(rawData.data.attributes.from) < new Date() && new Date() < new Date(rawData.data.attributes.to),
      lead_maintenance_end_time_text: rawData.data.attributes.description,
    };

    return mdata;
  } catch (e) {
    console.log(e);
  }
  return false;
};

module.exports.getInfoBar = async () => {
  try {
    const response = await fetch(getApiUrl("", "info-bar"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });

    const rawData = await response.json();

    const mdata = {
      infoBarFrom: rawData.data.attributes.from,
      infoBarTo: rawData.data.attributes.to,
      inProgressMobile:
        new Date(rawData.data.attributes.from) < new Date() && new Date() < new Date(rawData.data.attributes.to) && rawData.data.attributes.mobileActive,
      inProgressDesktop:
        new Date(rawData.data.attributes.from) < new Date() && new Date() < new Date(rawData.data.attributes.to) && rawData.data.attributes.desktopActive,
      content: rawData.data.attributes.content,
    };

    return mdata;
  } catch (e) {
    console.log(e);
  }
  return false;
};

module.exports.sendOffer = async data => {
  let leadData = {
    ...data,
  };

  try {
    const response = await fetch(getApiUrl(data.commodity, "sendOffer"), {
      method: "POST",
      body: JSON.stringify(leadData),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    const rawData = await response.json();

    if (rawData.status === "Success") {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

module.exports.sendLeadUniversalAPI = async data => {
  const leadData = {
    marketingSourceId: parseInt(data.source, 10),
    originUrl: window.location.protocol + "//" + window.location.hostname + window.location.pathname + window.location.search,
    commodity: data.commodity,
    regionId: data.region,

    calculationResult: {
      mndTotalPrice: data.mnd_total,
      competitionTotalPrice: data.konk_total,
      saving: data.save,
      competitionCommercialPriceListId: data.competitionCommercialPriceListId,
    },
    person: {
      phoneNumber: data.phone,
    },
    supplyPoints: [],
  };

  if (data.commodity === GAS) {
    leadData.calculationResult.gasAnnualConsumptionMwh = data.consumption / 1000;
  }
  else {
    leadData.calculationResult.eleAnnualConsumptionHighTariffMwh = data.consumption / 1000;
  }

  if (data.consumptionHighTariff) {
    leadData.calculationResult.eleAnnualConsumptionLowTariffMwh = data.consumption / 1000;
    leadData.calculationResult.eleAnnualConsumptionHighTariffMwh = data.consumptionHighTariff / 1000;
  }

  if (data.zipCode) {
    leadData.postalAddress = { zipCode: `${data.zipCode}` };
  }

  if (data.advance) {
    leadData.supplyPoints = [
      {
        monthlyAdvance: data.advance,
      },
    ];
  }

  if (data.phone_1_date && data.phone_1_time) {
    leadData.phoneReservations = [
      {
        date: data.phone_1_date,
        time: data.phone_1_time,
      },
    ];
  }

  try {
    const response = await fetch(getApiUrl(data.commodity, "leadApi"), {
      method: "POST",
      body: JSON.stringify(leadData),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    const rawData = await response.json();
    return {
      responseSuccess: true,
      // v případě failu dochází k populaci response.success hodnotou false
      // v případě successu je hodnota true, ale je přímo v response.success
      leadSuccess: (rawData.response?.success ?? rawData.success) === true,
      errors: rawData?.response?.errors ?? [],
    };

  } catch (e) {
    console.log(e);
  }

  return {
    responseSuccess: false,
    leadSuccess: false,
    errors: [],
  }
};

module.exports.sendLead = async data => {
  let formData = "";

  [
    "phone",
    "email",
    "source",
    "phone_1_date",
    "phone_1_time",
    "commodity",
    "region",
    "advance",
    "consumption",
    "units",
    "mnd_total",
    "konk_total",
    "konk_name",
    "konk_tariff",
    "save",
  ].forEach(item => {
    if (data[item]) {
      formData += item + "=" + encodeURIComponent(data[item]).replace(/%20/g, "+") + "&";
    }
  });

  try {
    const response = await fetch(getApiUrl("gas", "lead"), {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
    const rawData = await response.json();

    if (rawData.status === "Success") {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

module.exports.getCities = async zipcode => {
  const response = await fetch(`${getApiUrl("", "zipCodeUrl")}${zipcode}`);
  return await response.json();
};

module.exports.getOfferCodeIds = async () => {
  try {
    const response = await fetch(`${getApiUrl("", "offerCodes")}`);
    return await response.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

module.exports.getRetailOfferCodeIds = async offerCodesByCommodity => {
  try {
    const response = await fetch(`${getApiUrl("", "retailOfferCodes")}`, {
      body: JSON.stringify(offerCodesByCommodity),
      method: "POST",
    });
    return await response.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

module.exports.getTariffs = async (data, isCache) => {
  const cacheKey = await JSON.stringify({ data });

  if (cacheHas(cacheKey)) {
    return cacheGet(cacheKey);
  }

  let variant = `${data.commodity}Tariffs`;
  const requestUrl = getApiUrl(data.commodity, variant);

  let dataToFetch = {
    city: data.city,
    postcode: data.postcode,
    consumerType: "household",
    consumption: data.consumption,
  };

  if (data.commodity === "electricity") {
    dataToFetch = {
      city: data.city,
      postcode: data.postcode,
      circuitBreakerId: data.circuitBreaker ? data.circuitBreaker.split("_")[1] : DEFAULT_TARIFF_CIRCUIT_BREAKERS_ID,
      consumptionHighTariff: data.consumption_high_tariff,
      consumptionLowTariff: data.consumption_low_tariff ? data.consumption_low_tariff : 0,
      rateId: data.rate ? data.rate : DEFAULT_TARIFF,
    };
    if (data.amperes) {
      dataToFetch.circuitBreakerMaxAmperes = data.amperes;
    }
  }

  const response = await fetch(constructUrlForApiCall(requestUrl, dataToFetch));

  if (response) {
    try {
      const rawData = await response.json();
  
      cacheSet(cacheKey, rawData.data);
      return rawData.data;
    } catch (e) {
      return [];
    }
  }
};

module.exports.sendFeedback = async feedback => {
  try {
    let formData = new FormData();
    formData.append("feedback", feedback);

    const response = await fetch(getApiUrl("", "feedback"), {
      method: "POST",
      body: formData,
    });
    const rawData = await response.json();

    if (rawData.status === "ok") {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
};

module.exports.getPollIds = async surveyId => {
  let response = await fetch(`${getApiUrl("", "poll")}/${surveyId}`);

  return response.json();
};

module.exports.getPoll = async surveyId => {
  let response = await fetch(`https://api.surveyjs.io/public/Survey/getSurvey?surveyId=${surveyId}`);

  return response.json();
};

module.exports.sendPollResponse = async (postId, pollResponse) => {
  await fetch(`https://api.surveyjs.io/public/Survey/post`, {
    method: "POST",
    body: JSON.stringify({
      PostId: postId,
      SurveyResult: pollResponse,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getResults = async (resultId, questionName) => {
  const statusCode = 200;
  const time = 3000;

  try {
    let response = await promiseTimeout(fetch(`https://api.surveyjs.io/public/Survey/getResult?resultId=${resultId}&name=${questionName}`), time);

    if (response && response.status !== statusCode) {
      return await getResults(resultId, questionName);
    }

    return response.json();
  } catch (error) {
    return await getResults(resultId, questionName);
  }
};

module.exports.getResults = getResults;
