exports.components = {
  "component---src-pages-banner-vykup-elektriny-z-fotovoltaiky-banner-js": () => import("./../../../src/pages/banner/vykup-elektriny-z-fotovoltaiky-banner.js" /* webpackChunkName: "component---src-pages-banner-vykup-elektriny-z-fotovoltaiky-banner-js" */),
  "component---src-pages-elektrina-vyber-ceniku-js": () => import("./../../../src/pages/elektrina/vyber-ceniku.js" /* webpackChunkName: "component---src-pages-elektrina-vyber-ceniku-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ochrana-oznamovatelu-js": () => import("./../../../src/pages/ochrana-oznamovatelu.js" /* webpackChunkName: "component---src-pages-ochrana-oznamovatelu-js" */),
  "component---src-pages-plyn-vyber-ceniku-js": () => import("./../../../src/pages/plyn/vyber-ceniku.js" /* webpackChunkName: "component---src-pages-plyn-vyber-ceniku-js" */),
  "component---src-pages-prepis-energii-dedictvi-umrti-js": () => import("./../../../src/pages/prepis-energii/dedictvi-umrti.js" /* webpackChunkName: "component---src-pages-prepis-energii-dedictvi-umrti-js" */),
  "component---src-pages-prepis-energii-elektriny-plynu-js": () => import("./../../../src/pages/prepis-energii-elektriny-plynu.js" /* webpackChunkName: "component---src-pages-prepis-energii-elektriny-plynu-js" */),
  "component---src-pages-prepis-energii-novy-odber-js": () => import("./../../../src/pages/prepis-energii/novy-odber.js" /* webpackChunkName: "component---src-pages-prepis-energii-novy-odber-js" */),
  "component---src-pages-prepis-energii-obnoveni-odberu-js": () => import("./../../../src/pages/prepis-energii/obnoveni-odberu.js" /* webpackChunkName: "component---src-pages-prepis-energii-obnoveni-odberu-js" */),
  "component---src-pages-prepis-energii-stehovani-js": () => import("./../../../src/pages/prepis-energii/stehovani.js" /* webpackChunkName: "component---src-pages-prepis-energii-stehovani-js" */),
  "component---src-pages-prepis-energii-zmena-dodavatele-js": () => import("./../../../src/pages/prepis-energii/zmena-dodavatele.js" /* webpackChunkName: "component---src-pages-prepis-energii-zmena-dodavatele-js" */),
  "component---src-pages-vykup-elektriny-z-fotovoltaiky-js": () => import("./../../../src/pages/vykup-elektriny-z-fotovoltaiky.js" /* webpackChunkName: "component---src-pages-vykup-elektriny-z-fotovoltaiky-js" */),
  "component---src-pages-zajem-o-nabidku-1-js": () => import("./../../../src/pages/zajem-o-nabidku-1.js" /* webpackChunkName: "component---src-pages-zajem-o-nabidku-1-js" */),
  "component---src-pages-zajem-o-nabidku-2-js": () => import("./../../../src/pages/zajem-o-nabidku-2.js" /* webpackChunkName: "component---src-pages-zajem-o-nabidku-2-js" */),
  "component---src-templates-activation-pages-activation-page-js": () => import("./../../../src/templates/activation-pages/activation-page.js" /* webpackChunkName: "component---src-templates-activation-pages-activation-page-js" */),
  "component---src-templates-elektrina-s-bonusem-404-js": () => import("./../../../src/templates/elektrina-s-bonusem-404.js" /* webpackChunkName: "component---src-templates-elektrina-s-bonusem-404-js" */),
  "component---src-templates-homepages-dpi-homepage-js": () => import("./../../../src/templates/homepages/dpi-homepage.js" /* webpackChunkName: "component---src-templates-homepages-dpi-homepage-js" */),
  "component---src-templates-kalkulacka-tepelne-cerpadlo-js": () => import("./../../../src/templates/kalkulacka-tepelne-cerpadlo.js" /* webpackChunkName: "component---src-templates-kalkulacka-tepelne-cerpadlo-js" */),
  "component---src-templates-news-news-list-js": () => import("./../../../src/templates/news/news-list.js" /* webpackChunkName: "component---src-templates-news-news-list-js" */),
  "component---src-templates-news-news-page-js": () => import("./../../../src/templates/news/news-page.js" /* webpackChunkName: "component---src-templates-news-news-page-js" */)
}

